<template>
  <div>
    <div
      class="tw-flex tw-h-14 tw-bg-white tw-rounded-full shadow tw-px-1 tw-py-2 tw-justify-between"
    >
      <div
        class="tw-rounded-full tw-h-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer smallPadding tw-mx-1"
        :class="[!value ? 'tw-bg-primary tw-text-white' : 'tw-bg-gray-100 color-grey']"
        @click="switchInterest(null)"
      >
        <div>All</div>
      </div>
      <template v-for="interest in interestsList">
        <div
          :key="`interest_label_${interest}`"
          class="tw-rounded-full tw-h-full tw-flex tw-items-center tw-cursor-pointer dynamicPadding tw-capitalize tw-mx-1"
          :class="[
            active(interest)
              ? 'tw-bg-primary tw-text-white'
              : 'tw-bg-gray-100 color-grey'
          ]"
          @click="switchInterest(interest)"
        >
          <div>{{ interest }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import BalloonIcon from '@/assets/exploreinfluencers/balloon.svg';

export default {
  components: {
    // BalloonIcon,
  },
  props: {
    interests: Object,
    value: String
  },
  data: () => ({}),
  computed: {
    interestsList() {
      return [
        'adventure',
        'sports',
        'history',
        'relaxing',
        'work',
        'family',
        'beach',
        'romantic',
        'party'
      ];
    }
  },
  methods: {
    active(interest) {
      return this.value === interest;
    },
    switchInterest(interest) {
      this.$emit('input', interest);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.smallPadding {
  padding: 0 2vw;
  @include respond-to(lg) {
    padding: 0 1.5vw;
  }
  @include respond-to(md) {
    padding: 0 2vw;
  }
  font-family: 'MontserratSemiBold';
  font-size: 0.75vw;
}
.dynamicPadding {
  padding: 0 2.5vw;
  @include respond-to(lg) {
    padding: 0 2vw;
  }
  @include respond-to(md) {
    padding: 0 1.75vw;
  }
  @include respond-to(sm) {
    padding: 0 1vw;
  }
  font-family: 'MontserratSemiBold';
  font-size: 0.75vw;
}

.shadow {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}

.color-grey {
  color: #747378;
}
</style>
