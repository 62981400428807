<template>
  <div>
    <div class="tw-flex tw-justify-center tw-mb-10">
      <destinations-search v-model="search"/>
    </div>
    <div class="tw-w-100 tw-pb-24 tw-flex tw-flex-wrap">
      <template v-for="item in resDestinations">
        <destination-card
          :key="item.name"
          :bgImage="item.background_image"
          :city="item.name"
          :country="item.country"
          :trips="Math.floor(Math.random() * 1000)"
          class="tw-mr-0 sm:tw-mr-6 tw-mb-10"
          @click="handleClick(item)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DestinationCard from './DestinationCard.vue';
import DestinationsSearch from './DestinationsSearch.vue';

export default {
  name: 'destinations-slider',
  components: {
    DestinationCard,
    DestinationsSearch,
  },
  props: {
    items: Array,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    resDestinations() {
      return this.search ? this.items.filter(item => 
        item.name.toLowerCase().includes(this.search.toLowerCase()) ||
        item.country.toLowerCase().includes(this.search.toLowerCase())
      ) : this.items;
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push(`destination/${item.name}`);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>


