<template>
  <div>
    <div class="tw-mb-24">
      <interests-switcher :interests="categories" v-model="selectedInterests" />
    </div>
    <!-- <div class="tw-flex tw-justify-center tw-mb-10"> -->
    <!-- <explore-types-switcher :tabs="tabs" v-model="activeTabIndex" /> -->
    <!-- </div> -->
    <div>
      <template v-if="activeTabIndex === 0">
        <template v-if="!selectedInterests">
          <template
            v-for="category in categoriesByInterests.slice(0, this.batch)"
          >
            <category-slider
              :key="`category_slider_${category.title}`"
              v-bind="category"
            />
          </template>
          <div class="button-load-more tw-text-primary tw-flex tw-justify-center tw-align-center" @click="loadMore">Load more</div>
        </template>
        <template v-else>
          <div class="tw-font-poppins-medium tw-text-6xl tw-font-bold tw-mt-24 tw-mb-16">{{selectedInterestTitle}}</div>
          <div class="tw-flex tw-flex-wrap tw-justify-center">
            <!-- <influencer-card
              :influencer="experience.influencer"
              :avatar="experience.background_image"
              :bgImage="experience.cover_category"
              :match="calcMatch()"
            /> -->
            <carousel-card
            v-for="(item, index) in categoriesByInterests"
            :key="`influencer_${index}`"
            :items="[item.cover_category, ...item.story_array]"
            :bgImage="item.influencer_data.image"
            :coverImage="item.cover_category"
            :influencer="item.influencer"
            :likes="item.likes.toString()"
            :experienceName="item.name"
            :data="item"
            :cityName="item.city_name"
            :country="item.country"
            class="tw-mr-10 tw-mb-10"
            @click="goToInspiration(item)"
          />
          </div>
        </template>
      </template>
      <template v-else>
        <destinations :items="topExperiences" />
      </template>
    </div>
  </div>
</template>

<script>
// import ExploreTypesSwitcher from './controls/ExploreTypesSwitcher.vue';
import InterestsSwitcher from './controls/InterestsSwitcher.vue';
import categories from '@/assets/json/categories.json';
import { getExperiencesByCategoryApi } from '@/api/firebase';
import CategorySlider from './CategorySlider.vue';
import Destinations from './destinations/Destinations.vue';
// import InfluencerCard from './cards/InfluencerCard.vue';
import { mapActions, mapGetters } from 'vuex';
import CarouselCard from '../CarouselCard.vue';

export default {
  name: 'categories-container',
  components: {
    // ExploreTypesSwitcher,
    CategorySlider,
    Destinations,
    InterestsSwitcher,
    // InfluencerCard,
    CarouselCard
  },
  props: {
    interests: Array
  },
  data: () => ({
    tabs: [ 'INFLUENCER TRIPS', 'DESTINATIONS' ],
    activeTabIndex: 0,
    categories,
    categoriesList: [],
    selectedInterests: null,
    batch: 3
  }),
  computed: {
    ...mapGetters([ 'topExperiences' ]),
    categoriesByInterests() {
      let resArr = [];
      if (this.selectedInterests) {
        const interest = this.categoriesList.find(
          cat => cat.category === this.selectedInterests
        );
        resArr = interest?.experiences ? interest.experiences : [];
      } else {
        resArr = this.categoriesList;
      }
      return resArr;
    },
    selectedInterestTitle() {
      return this.categories[this.selectedInterests];
    },
    optimizeApiSrc() {
      // const url = this.item.influencer_data.image;
      // const strippedUrl = url.split('.jpg')[0];
      // const srcUrl = strippedUrl + '_200x200.jpg';
      // console.log(srcUrl);
      // return srcUrl;
      return this.categoriesByInterests;//.map( category => category.influencer_data.image.split('.jpg')[0] + '_200x200.jpg');
    }
  },
  async mounted() {
    !this.topExperiences.length && (await this.loadTopExperiences());
    await this.loadExperiencesData();
  },
  methods: {
    ...mapActions([ 'loadTopExperiences' ]),
    async loadExperiencesData() {
      const results = await Promise.allSettled(
        Object.entries(categories).map(async([ category, title ]) => {
          const experiences = await getExperiencesByCategoryApi(category);
          return {
            title,
            category,
            experiences
          };
        })
      );

      this.categoriesList = results
        .filter(res => res.status === 'fulfilled')
        .map(res => res.value)
        .filter(res => !!res.experiences);
    },
    calcMatch(min = 50, max = 100) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    loadMore() {
      this.batch = this.batch + 3;
    },
    goToInspiration(item) {
      this.$router.push(`inspiration/${item.exp_id}`);
    },
  }
};
</script>

<style lang="scss" scoped>
.button-load-more {
  width: 227px;
  height: 64px;
  background: #ffe4ec;
  border-radius: 36px;
  margin: auto;
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: -0.01rem;
}
</style>


