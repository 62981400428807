<template>
  <div class="tw-border-grey_light tw-border tw-rounded-full tw-w-96 tw-h-14 tw-flex tw-items-center tw-px-4">
    <input
    v-model="searchVal"
    class="tw-flex-1"
    placeholder="Search destinations"
    />
    <search-icon class="tw-w-5 tw-ml-4" />
  </div>
</template>

<script>
import SearchIcon from '@/assets/exploreinfluencers/search.svg';

export default {
  name: 'destinations-search',
  components: {
    SearchIcon,
  },
  props: {
    value: String,
  },
  computed: {
    searchVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>


