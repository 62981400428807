<template>
  <div class="hidden-xs-only">
    <div class="tw-h-full tw-pt-32 2xl:tw-pt-44 tw-pb-24 trips-container">
      <div
        class="tw-font-poppins-semi-bold tw-font-medium title tw-font-extrabold"
      >
        Explore trips
      </div>
      <div
        class="tw-font-poppins-semi-bold tw-font-medium title tw-font-extrabold tw-mb-16"
      >
        based on your <span style="color: #d62a53">interests</span>
      </div>
      <div class="tw-w-11/12">
        <categories-container :interests="interests" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CategoriesContainer from './categoriesContainer/CategoriesContainer.vue';
import categories from '@/assets/json/categories.json';

export default {
  async mounted() {
    const interestsStr = this.$route.query.interests;
    const interestsIndexes = interestsStr
      ? interestsStr.split('-').map(item => parseInt(item) - 1)
      : [];
    this.interests = Object.keys(categories).filter((val, index) =>
      interestsIndexes.includes(index)
    );
    interestsStr && (await this.loadExperiences(interestsStr));
  },
  data: () => ({
    interests: [],
    selectedCity: this.$route.query.city
  }),
  components: {
    CategoriesContainer
  },
  methods: {
    ...mapActions([ 'loadExperiences' ])
  },
  watch: {
    category(value) {
      this.getExperiencesByCategory(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/app.scss';

.left-alignment {
  margin-left: 4.75rem !important;
}

.trips-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-top: 46px;
  margin-left: 4.75rem !important;
}

.text-field::v-deep .v-input__slot {
  background: white !important;
  padding: 20px !important;
  width: 415px;
  height: 85px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) !important;
  border-radius: 60px !important;
}

.text-field::v-deep .v-select__selection {
  justify-content: center;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
}

@media (max-width: 442px) and (min-width: 300px) {
  .text-field::v-deep .v-input__slot {
    background: white !important;
    padding: 20px !important;
    width: 300px;
    height: 70px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) !important;
    border-radius: 60px !important;
  }
}

.title {
  font-weight: 400;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 6.5vw !important;
  line-height: 6.5vw;
  vertical-align: top;
  letter-spacing: -0.3vw !important;
  color: black;
  // @include respond-to(lg) {
  //   font-size: 5rem !important;
  //   line-height: 5rem;
  // }
  // @include respond-to(md) {
  //   font-size: 3.5rem !important;
  //   line-height: 3.5rem;
  // }
  // @include respond-to(sm) {
  //   font-size: 2.75rem !important;
  //   line-height: 3rem;
  // }
}
</style>
