<template>
  <rl-carousel v-model="slide" @swipe-recognized="clearInterval" align="left">
    <div slot-scope="{ wrapperStyles, slides: { count } }">
      <div class="tw-mt-4 tw-absolute tw--top-24 tw-right-0 md:tw--top-28">
        <div class="tw-flex">
          <div
            @click="handleClickRight(count)"
            class="
              tw-mr-8 tw-cursor-pointer arrow
              tw-bg-paragraph tw-bg-opacity-10
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
          >
            <v-icon color="black">mdi-chevron-left </v-icon>
          </div>
          <div
            @click="handleClickLeft(count)"
            class="
              arrow tw-cursor-pointer
              tw-bg-paragraph tw-bg-opacity-10
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
          >
            <v-icon color="black">mdi-chevron-right </v-icon>
          </div>
        </div>
      </div>
      <div class="tw-text-center tw-w-full sm:tw-w-auto">
        <div v-bind="wrapperStyles">
          <slot></slot>
        </div>
      </div>
    </div>
  </rl-carousel>
</template>

<script>
import { RlCarousel } from 'vue-renderless-carousel';
export default {
  name: 'bar-carousel',
  components: {
    RlCarousel
  },
  data() {
    return {
      slide: 0,
      interval: undefined
    };
  },
  methods: {
    clearInterval() {
      clearInterval(this.interval);
    },
    handleClickRight() {
      this.slide = this.slide - 1 < 0 ? 0 : this.slide - 1;
    },
    handleClickLeft(count) {
      this.slide = this.slide + 1 >= count - 2 ? count : this.slide + 1;
    }
  }
};
</script>

<style scoped>
svg {
  transition: color 0.5s ease;
}

.arrow {
  width: 2.5vw;
  height: 2.5vw;
}
</style>
