<template>
  <div
    @mousemove="isOnHover = true"
    @mouseleave="isOnHover = false"
    @touchmove="isOnHover = true"
    @click="$emit('click')"
  >
    <div class="tw-relative background-card">
      <div class="card"> 
        <div class="tw-bottom-5 tw-left-5 tw-absolute tw-z-10 tw-flex tw-flex-col tw-items-start">
          <div class="tw-font-poppins-semi-bold tw-text-3xl tw-text-white">{{city}}, {{country}}</div>
          <div class="tw-text-lg tw-text-white">{{trips}} Travelo Trip{{trips !== 1 ? 's' : ''}}</div>
        </div>
        <img
        :src="bgImage"
        class="tw-h-full tw-w-full tw-object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bgImage: String,
    city: String,
    country: String,
    description: String,
    trips: Number,
  },
  data: function() {
    return {
      isOnHover: false,
      cardHover: 'cardHover',
      card: 'card'
    };
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.story {
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}

.background-card {
  width: 380px;
  height: 480px;
  cursor: pointer;

  &:hover{
    .card {
      // width: 500px;
      // height: 758px;
      // top: -10%;
      // left: -20%;
      position: absolute;
      border-radius: 20px;
      overflow: hidden;
      z-index: 20;
      animation: increaseCard .35s alternate ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes increaseCard {
    from {
        transform: scale(1, 1);
    }
    to {
        transform: scale(1.3, 1.3);
    }
}

.card {
  width: 380px;
  height: 480px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
</style>
