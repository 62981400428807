<template>
  <div :style="{ height: '39vw' }">
    <div class="tw-font-poppins-medium font-text tw-font-bold tw-mt-2 tw-mb-16">
      {{ title }}
    </div>
    <div>
      <carousel class="tw-w-100 tw-ml-8">
        <rl-carousel-slide
          v-for="(item) in experiences"
          :key="item.description"
          class="tw-w-screen tw-flex tw-justify-center sm:tw-w-auto sm:tw-mr-10"
        >
          <carousel-card
            :items="[item.cover_category, ...item.story_array]"
            :bgImage="item.influencer_data.image"
            :coverImage="item.cover_category"
            :influencer="item.influencer"
            :likes="item.likes.toString()"
            :experienceName="item.name"
            :data="item"
            :cityName="item.city_name"
            :country="item.country"
            class="tw-mr-0"
            @click="handleClick(item)"
          />
        </rl-carousel-slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { RlCarouselSlide } from 'vue-renderless-carousel';

import Carousel from '../Carousel.vue';
import CarouselCard from '../CarouselCard.vue';

export default {
  name: 'category-slider',
  components: {
    Carousel,
    CarouselCard,
    RlCarouselSlide,
  },
  props: {
    title: String,
    category: String,
    experiences: Array,
  },
  methods: {
    handleClick(item) {
      this.$router.push(`inspiration/${item.exp_id}`);
    },
  },
  computed: {
    optimizeApiSrc() {
      return this.experiences;/*.map(experience => {
        console.log(experience.influencer_data.image.split('profilepic')[1]);
        const extension = experience.influencer_data.image.split('profilepic')[1] ? experience.influencer_data.image.split('profilepic')[1].split('?')[0] : '.jpg';
        return (
          experience.influencer_data.image.split('profilepic')[0] +
          'profilepic_200x200' + extension + '?alt=media'
        );
      });*/
    },
  },
};
</script>

<style lang="scss" scoped>
.font-text {
  font-size: 3.3vw;
}
</style>


