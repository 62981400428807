<template>
  <div class="root">
    <div class="tw-flex tw-mb-3 tw-items-center tw-flex-wrap">
      <v-icon color="white">mdi-map-marker-outline </v-icon>
      <div class="tw-ml-2 tw-text-sm md:tw-text-base">
        {{ cityName.toUpperCase() }},
      </div>
      <div class="tw-ml-2 cityName">
        {{ country.toUpperCase() }}
      </div>
    </div>
    <div class="card-title">{{ experienceName }}</div>
    <div class="tw-flex">
      <v-btn 
      class="rounded-pill tw-mt-6 primary--text discoverTrip px-14"
      @click="$emit('click')"
      >Discover Trip</v-btn>
    </div>
    <!-- <div class="tw-flex tw-mt-5 tw-items-center tw-justify-between">
      <UserIcon :bgImage="bgImage" :userName="userName" />
      <div class="tw-flex tw-items-center">
        <div class="likes">
          {{ likes !== 'NULL' ? `${likes} likes` : '0 like' }}
        </div>
        <div class="red-icon">
          <LikeIcon />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import UserIcon from './UserIcon.vue';
// import LikeIcon from '@/assets/landingPage/likeHand.svg';

export default {
  props: {
    bgImage: String,
    userName: String,
    likes: String,
    experienceName: String,
    cityName: String,
    country: String
  },
  components:{
    // UserIcon,
    // LikeIcon
  }
};
</script>

<style scoped>
.root {
  @apply tw-flex tw-flex-col;
}

.red-icon {
  @apply tw-flex tw-justify-center tw-items-center tw-bg-primary tw-w-10 tw-h-10 tw-rounded-full;
}

.card-title {
  font-family: 'PoppinsMedium';
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: start;
  font-size: 2.5rem;
  line-height: 2.75rem;
}

.likes {
  margin-right: 5px;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 25.875px;
}

.cityNAME {
  font-family: 'MontserratSemiBold';
  font-size: 0.75rem;
}

.discoverTrip {
  font-family: 'MontserratBold';
  font-size: 1rem;
  letter-spacing: -0.03rem;
  height: 54px !important;
}
</style>
